.menu {
    display: flex;
    flex-direction: row;
    font-family: 'Mulish', sans-serif;
    justify-content: space-between;
    width: 380px;
    margin: 40px;
    margin-right: 100px;
    a {
      color: #000000;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-decoration: none;
    }
    label {
      cursor: pointer;
    }
    .selected {
        color: #9D2449;
    }
    // border: 2px solid #68d028a4;
}