@import './config';
.container {
  display: flex;
  align-items: center;
  // cursor: pointer;
}

.button {
  width: 160px;
  height: 46px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F9FCFF 100%);
  border: 1px solid #CBD5E1;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
  cursor: pointer;

  &:hover {
    border-color: $color__default;
    .text {
      color: $color__default;
    }
  }

  &.selected {
    // box-shadow: 0px 0px 10px #9D2449;
    // box-shadow: inset 0 0 0 1px $color__press;
    border: 1px solid $color__press;
    .text {
      color: $color__press;
    }
    // padding-left: 8px;
  }

}

.square {
  width: 26px;
  height: 26px;
  border-radius: 4px;
}

.square--leve {
  background: #FDE047;
  border: 1px solid #FACC15;
}

.square--moderado {
  background: #FDBA74;
  border: 1px solid #FB923C;
}

.square--grave {
  background: #EF4444;
  border: 1px solid #DC2626;
}

.text {
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
  // &:hover {
  //   color: $color__default;
  // }
}

