.boletaContainer {
  padding: 20px;
}

.formGroup {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  .item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;

    .errorBox {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 44px;
      box-sizing: border-box;
      background-color: #FEF2F2;
      border: 1px solid #DC2626;
      border-radius: 4px;
      color: #DC2626;
      margin-top: 10px;
    }

    &.date {
      input {
        width: 100%;
      }
    }
  }
}

label {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 10px;
}

.input {
  background: #FFFFFF;
  border: 1px solid #CBD5E1;
  box-shadow: 0 1px 1px rgba(54, 56, 83, 0.1);
  border-radius: 4px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}