@import './functions';
@import './config';

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 810px;
  gap: 20px;
  padding: 30px;
  overflow: scroll;
  &.report {
    width: 1200px;
  }
  .formGroup {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: 'Mulish', sans-serif;
    justify-content: center;
    gap: 10px;
    input {
      font-family: 'Mulish', sans-serif;
      font-weight: 600;
      font-size: 16px;
    }
    &.directionComents {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      input {
        text-align: left;
        width: 555px;
      }
    }
    label {
      // border: 2px solid #d02895a4;
      // font-weight: 700px;
      font-weight: 700;
      font-size: 16px;
    }

    input {
      // border: 2px solid #28d0bfa4;
      border: none;
      height: 27px;
      padding: 10px;
      font-size: 16px;
      color: #333;
      background-color: #f5f5f5;
      text-align: left;
      border-radius: 4px;
      border: 1px solid #E2E8F0;
      //   width: 380px;
      // box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      cursor: default;

      &:read-only {
        // background-color: #f1f5f9;
        background-color: #F1F5F9;
      }
    }

    &.data {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        input {
            text-align: left;
            width: 100%;
          height: auto;
        }
    }
    .item{
      display: flex;
      cursor: pointer;
      flex-direction: column;
      font-family: 'Mulish', sans-serif;
      font-weight: 600;
      font-size: 16px;
      gap: 10px;
      flex: 1;

      &.comment {
        cursor: default;
      }
    }
    
    .directionInput {
      display: flex;
      // border: 1px solid #ae4018;
      justify-content: flex-end;
      flex-direction: row;
      flex: 1;
      width: 100%;
      position: relative;
      input {
        width: 100%;
        height: auto;
      }
      .itemMap {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        margin-top: 10px;
        width: 98px;
        height: 28px;
        background: #FFFFFF;
        /* 4T/7420 */
        border: 1px solid #9D2449;
        box-shadow: 0px 1px 1px rgba(54, 56, 83, 0.1);
        border-radius: 4px;
        gap: 6px;
        cursor: pointer;
        p {
          font-weight: 700;
          font-size: 12px;
          color: $color__default;
        }
        .locationDot {
          svg {
            width: 9px;
            height: 10px;
          }
        }
      }
    }

    //
    .comments {
      cursor: disabled;
      background-color: #F1F5F9;
      border: 1px solid #E2E8F0;
      border-radius: 4px;
      width: 360px;
      height: 208px;
      // padding: 10px;
      font-size: 16px;
      color: #333;
      p {
        padding: 10px;
        margin-top: 0px;
      }
      // overflow-wrap: break-word;
      // word-wrap: break-word;
    }
    .priority{
      display: flex;
      flex-direction: column;
      align-self: center;
      // justify-self: center;
      text-align: center;
      gap: 20px;

      .checkButtons {
        display: flex;
        gap: 20px;
      }
    }
  }

  .fotoContainer {
    width: 360px;
    height: 208px;
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
    // background-image: url(../../assets/images/report.png);
    background-size: contain;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .photos {
    width: 555px;
    height: 320px;
    background-color: #F1F5F9;
    border-radius: 4px;
  }
  .mapContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 810px;
    height: 600px;
    // width: 900px;
  }
  .photoView {
    width: 810px;
    height: 600px;
    background-color: #ddd;
    border-radius: 4px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
