@import '../../../components/reporteCiudadano/config';

.Formcontent {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.formGroup {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 30px;

  label {
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    font-size: 16px;
  }
  input {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #cbd5e1;
    box-shadow: 0px 1px 1px rgba(54, 56, 83, 0.1);
    border-radius: 4px;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 16px;
    // color: #94A3B8;
    height: auto;
    width: 280px;
    padding-left: 15px;

    &::placeholder {
      // padding-left: 15px;
      color: #94a3b8;
    }
  }

  input[type="password"] {
    color: #94a3b8;
    font-weight: 400;
    font-size: 18px;
    &::placeholder {
      // padding-left: 15px;
      color: #94a3b8;
    }
  }
  &.password {
    position: relative;

    .icon {
      color: #94a3b8;
      //   padding: 13px;
      position: absolute;
      //   left: 8px;
      top: 58px;
      right: 17px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  select {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #cbd5e1;
    box-shadow: 0px 1px 1px rgba(54, 56, 83, 0.1);
    border-radius: 4px;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 16px;
    // color: #94A3B8;
    height: 50px;
    width: 280px;
    padding-left: 15px;

    &::placeholder {
      // padding-left: 15px;
      color: #94a3b8;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;

  .errorBox {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 280px;
    height: 44px;
    box-sizing: border-box;
    background-color: #FEF2F2;
    border: 1px solid #DC2626;
    border-radius: 4px;
    color: #DC2626;
  }
  &.full {
    width: 100%;
    .errorBox {
      width: 100%;
    }
    input {
      width: auto;
    }
  }
}

.formGroup input:hover {
  border: 1px solid $color__hover;
}

.formGroup input:focus {
  border: 1px solid $color__press !important;
//   box-shadow: 0 0 3px $color__press !important;
//   -moz-box-shadow: 0 0 3px $color__press !important;
//   -webkit-box-shadow: 0 0 3px $color__press !important;
}

input:focus {
  outline-offset: 0px !important;
  outline: none !important;
}
select:focus {
  outline-offset: 0px !important;
  outline: none !important;
}

.formGroup {
  input[readonly] {
    cursor: default;
    background-color: #f1f5f9;
    border: 1px solid #cbd5e1 !important;

    &:hover {
      background-color: #f1f5f9;
      border: 1px solid #cbd5e1 !important;
    }

    &:focus {
      background-color: #f1f5f9;
      border: 1px solid #cbd5e1 !important;
    }
  }
}