@import '../../components/reporteCiudadano/config';


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 90px;
  .image {
    width: 214px;
    height: 60px;
    background-image: url(../../assets/images/logo-ayuntamiento.png);
    background-repeat: no-repeat;
    background-size: 214px 60px;
  }

}

.body {
  background-color: #ffffff;
  border-radius: 8px;
  margin: 22px;
  overflow: hidden;
}

// .headerContainer {
//   //   border: 2px solid #68d028a4;
//   //   width: 100%;
//   height: 81px;
//   display: flex;
//   flex: 1;
//   // font-family: 'Mulish', sans-serif;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0 16px;
//   border-bottom: 1px solid #cbd5e1;
// }

.printContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cbd5e1;
  // margin-top: 10px;
  padding: 10px;
  .return {
    // margin-top: 0px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      color: #9d2449;
      cursor: pointer;
      // margin-top: 0px;

      // border: 1px solid #9E1E4B;
      // margin-top: 40px;
      // font-family: 'Mulish', sans-serif;
      // font-weight: 400;
      // font-size: 16px;
      // color: #2E2E2E;
    }
    .icon {
      color: $color__default;
    }
  }
}

.title {
  color: #9d2449;
  font-size: 24px;
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  // width: 100vw;
  overflow-y: auto; 
  justify-content: center;
  box-sizing: border-box;
  padding: 30px;
}

.centered {
  align-items: center;
}


.buttons {
  display: flex;
  // align-items: end;
  justify-content: flex-end;
  margin-right: 30px;
  gap: 15px;
}

.scrollContainer {
  overflow-y: auto;
  max-height: 85vh;
}

.succesfull {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  text-align: center;
  gap: 116px;
  margin-top: 50px;
  // width: 600px;
  // height: 434px;
  // position: absolute;
  // border: 1px solid red;
  p {
    // border: 1px solid #0d7c60;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }

  .succes {
    // border: 1px solid #094a9a;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .icon {
      color: #64748b;
      color: #22C55E;
      width: 80px;
      height: 80px;
      // margin: 20px;
      &.rejected {
        color: #EF4444;
      }
    }
    label {
      color: #000000;
      font-weight: 700;
      font-size: 20px;
    }
  }

  &.error {
    margin: 0px;
    label {
      color: #EF4444;
      font-weight: 700;
      font-size: 20px;
    }
  }
}

.pills {
  display: inline-flex;
  gap: 10px;
}

.status {
  font-weight: 700;
  font-size: 10px;

  &.nuevo {
    color: $color__nuevo;
  }

  &.aprobado {
    color: $color__aprobado;
  }

  &.atendiendo {
    color: $color__atendiendo;
  }

  &.solucionado {
    color: $color__solucionado;
  }

  &.nosolucionado {
    color: $color__nosolucionado;
  }

  &.condonado{
    color: $color__condonado;
  }

  &.rechazado {
    color: $color__rechazado;
  }

  &.closed {
    color: $color__closed;
  }

  &.noatendido {
    color: $color__noatendido;
  }

  &.cerrado {
    color: $color__cerrado;
  }

  // &.user {
  //   border-color: #B38E5D;
  //   color: #B38E5D;
  // }
}

.searchInput {
  position: relative;
  input {
    width: 248px;
    height: auto;
    border-radius: 4px;
    border: 1px solid #cbd5e1;
    padding-left: 34px;
  }

  .icon {
    color: #64748b;
    padding: 5px;
    position: absolute;
    left: 8px;
    top: 10px;
    width: 16px;
    height: 16px;
  }
}

.searchInput input:hover {
  border: 1px solid $color__hover;
}

.searchInput input:focus {
  border: 1px solid $color__press !important;
}

.boletaBtn {
  background: #FFFFFF;
  border: 1px solid #9D2449;
  box-shadow: 0px 1px 1px rgba(54, 56, 83, 0.1);
  border-radius: 4px;
  padding: 9px;
  text-align: center;
  color: #9D2449;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  cursor: pointer;
}

.errorBox {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 270px;
  height: 44px;
  box-sizing: border-box;
  background-color: #FEF2F2;
  border: 1px solid #DC2626;
  border-radius: 4px;
  color: #DC2626;
  margin-top: 20px;
  margin-top: -20px;
  // margin: 10px;
  // margin-bottom: 20px;
  // padding: 10px;
}