.selectButton {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  background-color: #ffffff;
  overflow: hidden;
  &.open {
    overflow: visible;
  }
}

.selectedValue {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0 0 0 16px;
    cursor: pointer;
    text-align: left;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
  }
}

.dropdownIcon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.dropdownIcon svg {
  font-size: 16px;
}

.optionsList {
  position: absolute;
  top: 100%;
  left: -2px;
  width: 100%;
  margin: 3px 0 0 0;
  padding: 8px 0;
  border: 1px solid #cbd5e1;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  z-index: 1;
  list-style: none;
  overflow-y: auto;
  max-height: 160px;
}

.optionsList li {
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  cursor: pointer;
}

.optionsList li:hover {
  background-color: #f3f3f3;
}
