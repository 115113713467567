@import '../../scss/functions';
@import './config';
@import '../../scss/colors';

.modalView {
    // border: 2px solid #68d028a4;
  background-color: rgba(59, 61, 75, 0.7);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;

  &.hidden {
    background-color: transparent;
    pointer-events: none;

    .modalViewContainer {
      display: none;
    }
  }

  .modalViewContainer {
    // border: 2px solid #d03c28a4;
    // border-top: 1px solid #CBD5E1;;
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
    // height: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100vh;
    overflow: scroll;
  }

  .modalViewHeader{
    padding-top: 30px;
    position: relative;
    border-bottom: 1px solid #CBD5E1;
    width: 100%;
    // border: 1px solid #9E1E4B;

    h2 {
      margin-top: 0px;
      font-family: 'Mulish', sans-serif;
      text-align: center;
      font-weight: 700;
      font-size: 20px;
      // border: 1px solid #1e319e;
    }

    .close {
      align-items: center;
      background-color: white;
      border-radius: 100%;
      cursor: pointer;
      color: #2E2E2E;
      display: flex;
      height: 44px;
      justify-content: center;
      position: fixed;
      right: 20px;
      top: 20px;
      width: 44px;
    }

    .printContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-top: 10px;
      padding: 10px;
      .return {
        // margin-top: 0px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
  
        p {
          color: #9d2449;
          cursor: pointer;
          // margin-top: 0px;
  
          // border: 1px solid #9E1E4B;
          // margin-top: 40px;
          // font-family: 'Mulish', sans-serif;
          // font-weight: 400;
          // font-size: 16px;
          // color: #2E2E2E;
        }
        .icon {
          color: $color__default;
        }
      }
    }
  }

  .buttonsGroupRight {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 40px;
    margin-right: 30px;
  }
}