/*----- Colors -----*/
$color__call_to_action: #16A34A;
// $color__call_to_action_secondary: #50AF73;

$color__destructive: #EF4444;
$color__destructive_secondary: #DC2626;

$color__disabled: #F1F5F9;
$color__text_default: #FFFFFF;

$color__text_disabled: #CBD5E1;

$color__tertiary: #F4F7FC;
$color__4t: #9D2449;
$color__default: #9D2449;
$color__hover: #B12551;
$color__press: #7B203C;

// color reports
$color__nuevo: #3B82F6;
$color__aprobado: #9D2449;
$color__atendiendo: #FBBF24;
$color__solucionado: #14B8A6;
$color__nosolucionado: #F97316;
$color__condonado: #6366F1;
$color__rechazado: #64748B;
$color__closed: #16A34A;
$color__noatendido: #DC2626;
$color__cerrado: #16A34A;

/*----- Settings -----*/
$settings__scale: 1;
$settings__border_radius: 4px * $settings__scale;
