.page {
//   align-items: center;
  margin: auto;
  // border: 2px solid #68d028a4;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
//   justify-content: space-around;
  background-color: #E2E8F0;
  position: absolute;
}
  
.header {
  display: flex;
  font-family: 'Mulish', sans-serif;
  justify-content: space-between;
  background-color: #FFFFFF;
  height: 90px;
  .image{
    width: 214px;
    height: 60px;
    background-image: url(../../assets/images/logo-ayuntamiento.png);
    background-repeat: no-repeat;
    background-size: 214px 60px;
  }

  // a {
  //   padding: 40px;
  // }
  // .menu {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   // height: 16px;
  //   width: 457px;
  //   margin: 40px;
  //   a {
  //     color: #000000;
  //     font-family: 'Mulish';
  //     font-style: normal;
  //     font-weight: 600;
  //     font-size: 14px;
  //     line-height: 16px;
  //   }
  //   border: 2px solid #68d028a4;
  // }

  // .user {
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-start;
  //   padding: 40px;
  //   gap: 10px;
  //   // border: 2px solid #68d028a4;

  //   .avatar {
  //     width: 50px;
  //     height: 50px;
  //     background: #F1F5F9;
  //     border-radius: 8px;
  //     // border: 2px solid #284ad0a4;
  //     margin-right: 10px;
  //   }
    
  //   .text {
  //     // border: 2px solid #7628d0a4;
  //     font-size: 16px;
  //   }
    
  //   .arrow {
  //     // font-size: 24px;
  //   }
    
  // }
  
}
  
.body {
  // align-items: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: 'Mulish', sans-serif;
  height: 100%;
  margin: 22px;
  width: calc(100% - 44px);
}
// .options {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin-top: 100px;
//     // border: 2px solid #2836d0a4;
//     .title {
//       color: #9D2449;
//       font-family: 'Mulish';
//       font-style: normal;
//       font-weight: 400;
//       font-size: 24px;
//       line-height: 24px;
//       margin-top: 0;
//     }
        
//     .description {
//     margin: 10px 0;
//     }
      
//     .divider {
//       border: none;
//       background-color: #CBD5E1;
//       height: 1px;
//       margin: 50px 0;
//       width: 700px;
//     }
        
//     .items {
//     align-items: center;
//     display: flex;
//     justify-content: center;
    
//     }
    
//     .itemContainer {
//       text-align: center;
//       p {
//         margin: 10px;
//       }
//     }

//     .item {
//       align-items: center;
//       background: linear-gradient(180deg, #FFFFFF 0%, #F3F8FB 99.97%);
//       border: 1px solid #F1F5F9;
//       box-shadow: 0px 2px 2px rgba(55, 60, 77, 0.15), inset 0px -2px 0px #E6EBF3;
//       border-radius: 24px;
//       display: flex;
//       height: 120px;
//       justify-content: center;
//       width: 120px;
//       margin: 0 50px;

//       .icon {
//         color: #9D2449;
//         font-size: 60px;
//         // line-height: 90px;
//         // width: 34px;
//         // height: 62px;
//       }
//     }
// }
