@import './functions';
@import './config';

.button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;

  & > span {
    border-radius: 4px;
    display: inline-flex;
    //min-width: 200px;
    overflow: hidden;

    .icon-wrapper {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      padding: (12px * $settings__scale);
      // padding: 12px;
      transition: background-color 0.25s ease-in-out;

      .icon {
        height: rem(18px * $settings__scale);
        width: rem(18px * $settings__scale);
        // height: 18px;
        // width: 18px;
        color: white;
        transition: color 0.25s ease-in-out;
      }
      .print {
        height: rem(18px * $settings__scale);
        width: rem(18px * $settings__scale);
        color: $color__default;
        transition: color 0.25s ease-in-out;
      }
    }

    &[class*="button-style-"] {
      transition: background-color 0.25s ease-in-out;

      &[class*="destructive"] {
        background-color: $color__destructive;

        .icon-wrapper {
          background-color: $color__destructive_secondary;
        }
      }

      &[class*="delete"] {
        background-color: white;
        border: 1px solid $color__destructive;

        .label {
          color: $color__destructive;
        }
      }

      &[class*="positive"] {
        background-color: #22C55E;

        .icon-wrapper {
          background-color: #16A34A;
        }
      }

      &[class*="default"] {
        background-color: white;
        border: 1px solid $color__default;

        .label {
          color: $color__default;
        }
      }

      &[class*="action"] {
        background-color: $color__default;
        border: 1px solid $color__default;

        .label {
          color: $color__text_default;
        }
      }
    }

    .label {
      color: white;
      flex: 1;
      font-weight: 700;
      font-family: 'Mulish', sans-serif;
      font-size: 16px;
      // font-size: 14px;
      padding: 10px 24px;
      text-align: center;
      transition: color 0.25s ease-in-out;
    }
  }
}
