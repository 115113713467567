* {
    box-sizing: border-box;
}

figure {
    display: inline-block;
}

input {
    padding: 15px;
    height: auto;
}