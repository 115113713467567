.options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    // border: 2px solid #2836d0a4;
    .title {
      color: #9D2449;
    //   font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      margin-top: 0;
    }
        
    .description {
    margin: 10px 0;
    }
      
    .divider {
      border: none;
      background-color: #CBD5E1;
      height: 1px;
      margin: 50px 0;
      width: 780px;
    }
        
    .items {
      align-items: center;
      display: flex;
      gap: 100px;
      justify-content: center;
      // border: 1px solid #1362b2;
    }
    
    // .itemContainer {
    //   text-align: center;
    //   p {
    //     margin: 10px;
    //   }
    // }

    // .item {
    //   align-items: center;
    //   background: linear-gradient(180deg, #FFFFFF 0%, #F3F8FB 99.97%);
    //   border: 1px solid #F1F5F9;
    //   box-shadow: 0px 2px 2px rgba(55, 60, 77, 0.15), inset 0px -2px 0px #E6EBF3;
    //   border-radius: 24px;
    //   display: flex;
    //   height: 120px;
    //   justify-content: center;
    //   width: 120px;
    //   margin: 0 50px;

    //   .icon {
    //     color: #9D2449;
    //     font-size: 60px;
    //     // line-height: 90px;
    //     // width: 34px;
    //     // height: 62px;
    //   }
    // }
}