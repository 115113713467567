@import '../../components/reporteCiudadano/config';

.page {
  //   align-items: center;
  margin: auto;
  // border: 2px solid #68d028a4;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  //   justify-content: space-around;
  background-color: #e2e8f0;
  position: absolute;
}

.header {
  display: flex;
  font-family: "Mulish", sans-serif;
  justify-content: space-between;
  background-color: #ffffff;
  height: 90px;
  .image {
    width: 214px;
    height: 60px;
    background-image: url(../../assets/images/logo-ayuntamiento.png);
    background-repeat: no-repeat;
    background-size: 214px 60px;
  }
}

.body {
  // align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: "Mulish", sans-serif;
  height: 100%;
  margin: 22px;
  width: calc(100% - 44px);
  overflow: hidden;
  overflow-y: auto; 
}
.headerContainer {
  //   border: 2px solid #68d028a4;
  //   width: 100%;
  height: 81px;
  display: flex;
  flex: 1;
  // font-family: 'Mulish', sans-serif;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid #cbd5e1;
}

.title {
  color: #9d2449;
  font-size: 24px;
  font-weight: bold;
}

.container {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  gap: 20px;
  .selectInput {
    cursor: pointer;
    display: flex;
    flex: 1;
    width: 268px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  font-family: "Mulish", sans-serif;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
//   border: 1px solid #64748b;
  //   padding: 10px;
//   gap: 30px;

 .titleContent {
  margin-top: 15px;
 }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 30px;
    margin-top: 30px;
    // border: 1px solid #64748b;
    label {
      margin-bottom: 30px;
    }
  }
  .cards {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .card {
    background: #ffffff;
    border: 1px solid #64748B;
    box-shadow: 0px 2px 2px #f7f7fa, inset 0px -2px 0px #e6ebf3;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px;
    width: 244px;
    height: 120px;
    box-sizing: border-box;
    padding: 20px;
    // gap: 10px;
    label {
    //   border: 1px solid #64748b;
      font-weight: 400;
      font-size: 36px;
      margin: 10px;
    }
    .titles {
    //   border: 1px solid #64748b;
      margin: 5px;
      font-weight: 500;
      font-size: 16px;
    }
    .percentage {
    //   border: 1px solid #64748b;
      color: #64748B;
      margin: 0px;
      font-weight: 500;
      font-size: 16px;
    }

    &.aprobado, &.solucionado {
        border: 1px solid #22C55E;
        label {
            color: #22C55E;
        }

    }

    &.rechazado, &.ignorados {
        border: 1px solid $color__noatendido;
        label {
            color: $color__noatendido;
        }
    }

    &.nosolucionados {
      border: 1px solid $color__nosolucionado;
      label {
        color: $color__nosolucionado;
      }
    }

    &.asignado {
        border: 1px solid #9D2449;
        label {
            color: #9D2449;
        }
    }

    &.condonado {
        border: 1px solid #F97316;
        label {
            color: #EA580C;
        }
    }

    &.closed {
      border: 1px solid $color__closed;
      label {
        color: $color__closed;
      }
    }
  }
}

.selectButton {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  background-color: #ffffff;
  overflow: hidden;
  &.open {
    overflow: visible;
  }
}

.selectedValue {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0 0 0 16px;
    cursor: pointer;
    text-align: left;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
  }
}

.dropdownIcon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.dropdownIcon svg {
  font-size: 16px;
}

.optionsList {
  position: absolute;
  top: 100%;
  left: -2px;
  width: 100%;
  margin: 3px 0 0 0;
  padding: 8px 0;
  border: 1px solid #cbd5e1;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  z-index: 1;
  list-style: none;
  overflow-y: auto;
  max-height: 160px;
}

.optionsList li {
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  cursor: pointer;
}

.optionsList li:hover {
  background-color: #f3f3f3;
}
