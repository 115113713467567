@import '../../components/reporteCiudadano/config';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 90px;
  .image {
    width: 214px;
    height: 60px;
    background-image: url(../../assets/images/logo-ayuntamiento.png);
    background-repeat: no-repeat;
    background-size: 214px 60px;
  }
}

.body {
  // align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  // display: flex;
  // flex-direction: column;
  // height: 100%;
  margin: 22px;
  overflow: hidden;
  // width: calc(100% - 44px);
}

.pills {
  display: inline-flex;
  gap: 10px;
}

.tag {
  background: transparent;
  border: 1px solid black;
  border-radius: 6px;
  color: black;
  font-weight: 600;

  &.root {
    border-color: #9D2449;
    color: #9D2449;
  }

  &.admin {
    border-color: #621132;
    color: #621132;
  }

  &.officer {
    border-color: #285C4D;
    color: #285C4D;
  }

  &.user {
    border-color: #B38E5D;
    color: #B38E5D;
  }
}

.searchInput {
  position: relative;
  input {
    width: 248px;
    height: auto;
    border-radius: 4px;
    border: 1px solid #cbd5e1;
    padding-left: 34px;
  }

  .icon {
    color: #64748b;
    padding: 5px;
    position: absolute;
    left: 8px;
    top: 10px;
    width: 16px;
    height: 16px;
  }
  //   .button {
  //     display: inline-flex;
  //     // margin: 20px;
  //   }
}

.searchInput input:hover {
  border: 1px solid $color__hover;
}

.searchInput input:focus {
  border: 1px solid $color__press !important;
  // -moz-box-shadow: 0 0 3px $color__press !important;
  // -webkit-box-shadow: 0 0 3px $color__press !important;
}

input:focus {
  outline-offset: 0px !important;
  outline: none !important;
}

//

.icon {
  width: 24px;
  height: 24px;
  //   background-image: url("ruta/a/la/imagen/lupa.png");
  background-size: cover;
}

.succesfull {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  text-align: center;
  gap: 116px;
  margin-top: 50px;
  // width: 600px;
  // height: 434px;
  // position: absolute;
  // border: 1px solid red;
  p {
    // border: 1px solid #0d7c60;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }

  .succes {
    // border: 1px solid #094a9a;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .icon {
      color: #64748b;
      color: #22C55E;
      width: 80px;
      height: 80px;
      // margin: 20px;
      &.rejected {
        color: #EF4444;
      }
    }
    label {
      color: #000000;
      font-weight: 700;
      font-size: 20px;
    }
  }

  &.error {
    margin: 0px;
    label {
      color: #EF4444;
      font-weight: 700;
      font-size: 20px;
    }
  }
}