@import '../../components/reporteCiudadano/config';

.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

.loginInnerContainer {
  display: flex;
  flex: 1;
  gap: 80px;
  font-family: 'Mulish', sans-serif;
  justify-content: center;
  align-items: center;
  width: 90vw;
  height: 90vh;
  box-sizing: border-box;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // font-family: 'Mulish', sans-serif;
  padding: 40px;
  // border: 1px solid #ccc;
  border-radius: 10px;
  // width: 570px;
  // height: 484px;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  // border: 1px solid #56e60e;
  .errorBox {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 570px;
    height: 44px;
    box-sizing: border-box;
    background-color: #FEF2F2;
    border: 1px solid #DC2626;
    border-radius: 4px;
    color: #DC2626;
    margin-top: 20px;
    // margin: 10px;
    // margin-bottom: 20px;
    // padding: 10px;
  }
}

.loginHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
  // font-family: 'Mulish', sans-serif;
  label {
    color: #22C55E;
  }
  .image {
    width: 214px;
    height: 60px;
    background-image: url(../../assets/images/logo-ayuntamiento.png);
    background-repeat: no-repeat;
    background-size: 214px 60px;
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    label {
      font-size: 24px;
      font-weight: 400px;
      // margin-bottom: 10px;
      color: #9d2449;
    }
  
    p {
      font-size: 16px;
      margin: 0;
    }
    // .success {
    //   label {
    //     color: #22C55E;
    //   }
    // }
    // border: 1px solid #0e6fe6;
  }
  .icon {
    // color: #64748b;
    color: #22C55E;
    width: 40px;
    height: 40px;
    margin: 20px;
  }
  // border: 1px solid #0e6fe6;
}

.loginFormFields {
  display: flex;
  flex-direction: column;
  // margin-bottom: 30px;
  // border: 1px solid #e6b70e;
}

.loginFormField {
  display: flex;
  flex-direction: column;
  text-align: center;
  // margin-bottom: 10px;
  margin-bottom: 20px;
  width: 308px;

  label {
    font-size: 16px;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    height: 40px;
    // padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
    text-align: center;
    // color: #94A3B8;
    // font-weight: 400;
    // font-size: 16px;
  }
  input[type="password"] {
    color: #94A3B8;
    font-weight: 400;
    font-size: 16px;

  }
  &.password {
    position: relative;

    .icon {
      color: #64748b;
    //   padding: 13px;
      position: absolute;
      //   left: 8px;
      top: 37px;
      right: 13px;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }
}

.loginFormField input:hover {
  border: 1px solid $color__hover;
}

.loginFormField input:focus {
  border: 1px solid $color__press !important;
}
  
input:focus {
  outline-offset: 0px !important;
  outline: none !important;
}

.loginFooter {
  text-align: center;
  // border: 1px solid #0ee67e;
}

// .loginFooter p:hover {
//   color: $color__hover;
// }

.loginButton {
  width: 308px;
  height: 40px;
  background-color: $color__default;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  // margin-bottom: 20px;
  // border: 1px solid #0e6fe6;
}

.loginButton:hover {
  background-color: $color__hover;
}

.loginButton:active {
  background-color: $color__press;
}

.forgotPassword {
  color: #9d2449;
  cursor: pointer;
  // border: 1px solid #9E1E4B;
  margin-top: 40px;
}

.containerBackgorund {
  // border: 1px solid #dd500e;
  display: flex;
  // width: 690px;
  // height: 984px;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 10px;
  scale: (1.0);
}

.loginBackground {
  display: flex;
  justify-content: center;
  align-self: center;
  // width: 100%;
  // height: 100%;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  // width: 214px;
  // height: 60px;
  background-image: url(../../assets/images/eagle.png);
  background-size: cover;
  border-radius: 1.5%;
  // background-repeat: no-repeat;
  // background-size: contain;
  // box-sizing: border-box;
  // background-size: 690px 984px;
}

.return {
  display: flex;
  align-items: center;
  gap: 10px;
  // border: 1px solid #ccc;
}
