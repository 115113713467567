.page {
  //align-items: center;
  margin: auto;
  // border: 2px solid #68d028a4;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  //   justify-content: space-around;
  background-color: #e2e8f0;
  position: absolute;
}
