@import './config';

.header {
  //   border: 2px solid #68d028a4;
  //   width: 100%;
  height: 81px;
  display: flex;
  flex: 1;
  // font-family: 'Mulish', sans-serif;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid #cbd5e1;
}

.title {
  color: #9d2449;
  font-size: 24px;
  font-weight: bold;
}

.container {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    gap: 20px;
    overflow: scroll;
}

.datatable {
  display: flex;
  font-family: 'Mulish', sans-serif;
  justify-content: center;
  padding: 10px;
  // height: 100vh;
  max-height: 75vh;
  overflow: scroll;
}
//////////
table {
  // border: 2px solid #68d028a4;
  width: 100%;
  border-collapse: collapse;
}

thead th,
tbody td {
  .icon {
    margin-left: 5px;
    width: 15px;
    height: 15px;
    //   background-image: url("ruta/a/la/imagen/lupa.png");
    background-size: cover;
    scale: .8;
  }
  // border: 2px solid #2833d0a4;
  text-align: left;
  padding: 8px;
}

thead th {
  font-weight: bold;
  //   background-color: #f5f5f5;
}

tbody tr:nth-child(even) {
  background-color: #f8fafc;
}
tbody tr:hover {
  cursor: pointer;
  background-color: rgba(212, 193, 156, 0.3);
}
.lastCol {
  text-align: right;
}

.point {
  position: relative;
  padding-left: 18px; // 10px + 8px (icon width)
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #dce1e6;
  }
}
