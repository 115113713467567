@import "../../../scss/functions";
// @import '../../../scss/typography';
@import "../../../scss/colors";

.module {
  a {
    text-decoration: none;
  }
  .itemContainer {
    text-align: center;
    // border: 1px solid #CBD5E1;
    box-sizing: border-box;
    p {
      color: black;
      font-weight: 400;
      font-size: 16px;
      margin: 10px;
      text-decoration: none;
      // &.disabled {
      //   color: #94A3B8;
      // }
    }
    

    .item {
      align-items: center;
      background: linear-gradient(180deg, #FFFFFF 0%, #F8FBFF 99.97%);
      border: 1px solid #CBD5E1;
      box-shadow: 0px 2px 2px #F7F7FA, inset 0px -2px 0px #E6EBF3;
      border-radius: 24px;
      display: flex;
      height: 120px;
      justify-content: center;
      width: 120px;
      // padding: 40px;
      transition: box-shadow 0.3s ease-in-out;
      box-sizing: border-box;
      margin: 0px;
      &.item:hover {
        border: 1px solid #9D2449;
      }
      &.item:active {
        border: 1px solid #9D2449;
      }

      // &.disabled {
      //   p {
      //     color: #94A3B8;
      //     text-decoration: line-through;
      //   }
      // }

    }
  }

  &.disabled {
    pointer-events: none;
    p {
      color: #94A3B8;
    }
    .itemContainer .item {
      background: linear-gradient(180deg, #FFFFFF 0%, #F8FBFF 99.97%);
      box-shadow: 0px 2px 2px #F7F7FA, inset 0px -2px 0px #E6EBF3;
      border: none;
    }
  }
}
