.container {
  display: flex;
  position: relative;
  margin-right: 10px;
  z-index: 1
  // margin-bottom: 20px;
}

.user {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 40px;
  gap: 10px;
  position: relative;
  .item:hover {
    box-shadow: 0px 0px 10px #9D2449;
  }
  
  .avatar {
    width: 50px;
    height: 50px;
    background: #F1F5F9;
    border-radius: 8px;
    margin-right: 10px;
  }
  
  .text {
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    font-size: 14px;
  }
}

.dropdown {
  position: absolute;
  margin-top: -30px;
  top: 100%;
  right: 0;
}

.dropdownContent {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 0px 1px rgba(17, 24, 39, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-top: 26px;
  overflow: hidden;
  padding: 20px;
  width: 266px;
}

.item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 22px;
}

.item:hover .icon,
.item:hover .label {
  color: #B12551;
}

.icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.label {
  font-size: 16px;
}

.separator {
  margin: 22px;
  border: 0;
  height: 1px;
  background-color: #E5E7EB;
  width: 120%;
  margin-left: -20px;
}

.logout {
  margin-bottom: 0;
}

.redText {
  color: red;
}

// .user {
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     padding: 40px;
//     gap: 10px;
//     // border: 2px solid #68d028a4;

//     .avatar {
//       width: 50px;
//       height: 50px;
//       background: #F1F5F9;
//       border-radius: 8px;
//       // border: 2px solid #284ad0a4;
//       margin-right: 10px;
//     }
    
//     .text {
//       // border: 2px solid #7628d0a4;
//       font-size: 16px;
//     }
    
//     .arrow {
//       // font-size: 24px;
//     }
    
//   }