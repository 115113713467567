@import "../../../components/reporteCiudadano/config";

.Formcontent {
  display: flex;
  flex-direction: column;
  padding: 30px;
  
  .mapContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 810px;
    height: 600px;
    // width: 900px;
  }
  .photoView {
    width: 810px;
    height: 600px;
    background-color: #ddd;
    border-radius: 4px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.centered {
  align-items: center;
}

.formGroup {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 30px;

  label {
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    font-size: 16px;
  }

  input {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-shadow: 0px 1px 1px rgba(54, 56, 83, 0.1);
    border-radius: 4px;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 16px;
    // color: #94A3B8;
    height: auto;
    width: 330px;
    padding-left: 15px;

    &::placeholder {
      // padding-left: 15px;
      color: #94a3b8;
    }

    &:read-only {
      cursor: default;
      background-color: #f1f5f9;
      border: 1px solid #cbd5e1;
      // color: #777777;
    }
  }

  .dataText {
    background-color: #f1f5f9;
    border: 1px solid #cbd5e1;
    box-shadow: 0px 1px 1px rgba(54, 56, 83, 0.1);
    border-radius: 4px;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 16px;
    min-height: 60px;
    padding: 10px;
  }

  select {
    cursor: pointer;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #cbd5e1;
    box-shadow: 0px 1px 1px rgba(54, 56, 83, 0.1);
    border-radius: 4px;
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 16px;
    // color: #94A3B8;
    height: 46px;
    // width: 280px;
    padding-left: 15px;

    &::placeholder {
      // padding-left: 15px;
      color: #94a3b8;
    }
  }
  &.photosContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    input {
      flex: 1;
      width: 100%;
    }
  }
  .fotoContainer {
    width: 555px;
    height: 280px;
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
    // background-image: url(../../assets/images/report.png);
    background-size: contain;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    position: relative;

    .pill {
      display: flex;
      position: absolute;
      align-items: center;
      width: auto;
      height: 20px;
      background: #ffffff;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      p {
        padding: 4px;
        font-weight: 700;
        font-size: 12px;
      }
      &.moderado {
        border: 1px solid #fb923c;
      }
      &.bajo {
        border: 1px solid #FDE047;
      }
      &.alto {
        border: 1px solid #EF4444;
      }

      .square {
        width: 20px;
        height: 100%;
        // background-color: #fb923c;
        // border-top-left-radius: 2px;
        // border-bottom-left-radius: 2px;
        &.moderado,&.medio {
          background-color: #fb923c;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &.bajo {
          background-color: #FDE047;
        }
        &.alto {
          background-color: #EF4444;
        }
      }
    }

    .pill {
      right: 15px;
      top: 15px;
    }
  }

  .directionInput {
    display: flex;
    // border: 1px solid #ae4018;
    justify-content: flex-end;
    flex-direction: row;
    flex: 1;
    width: 100%;
    position: relative;
    input {
      //   width: 100%;
    }
    .itemMap {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      margin-top: 10px;
      width: 98px;
      height: 28px;
      background: #ffffff;
      /* 4T/7420 */
      border: 1px solid #9d2449;
      box-shadow: 0px 1px 1px rgba(54, 56, 83, 0.1);
      border-radius: 4px;
      gap: 6px;
      cursor: pointer;
      p {
        font-weight: 700;
        font-size: 12px;
        color: $color__default;
      }
      .locationDot {
        svg {
          width: 9px;
          height: 10px;
        }
      }
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  // flex: 1;
  width: 100%;

  input {
    width: auto;
    flex: 1;
  }

  & > .formGroup {
    margin-bottom: 0;
  }

  &.open {
    cursor: pointer;
  }
  &.full {
    width: 325%;
  }
}

// .formGroup input:hover {
//   border: 1px solid $color__hover;
// }

// .formGroup input:focus {
//   border: 1px solid $color__press !important;
// //   box-shadow: 0 0 3px $color__press !important;
// //   -moz-box-shadow: 0 0 3px $color__press !important;
// //   -webkit-box-shadow: 0 0 3px $color__press !important;
// }

// input:focus {
//   outline-offset: 0px !important;
//   outline: none !important;
// }
// select:focus {
//   outline-offset: 0px !important;
//   outline: none !important;
// }
